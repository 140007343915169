<template>
  <section class="select-pdf-section">
    <div class="pdf-logo">
      <img src="@/assets/img/cineflares-pro-logo-black.webp" alt="" />
    </div>

    <div>
      <img
        :src="`data:image/png;base64,${select.flareImageBase64 || baseImgUrl}`"
      />
    </div>

    <div class="pdf-head-data">
      <img src="@/assets/img/lens-icon.svg" width="30" alt="" />
      <div class="pdf-lens-head">
        {{ select.name }}
      </div>
      <div class="pdf-lens-head">
        {{ select.focalLength }}
      </div>
      <div class="pdf-lens-head">
        {{ select.transmission }}
      </div>
      <img
        :src="`data:image/png;base64,${select.iconBase64 || iconBase64}`"
        alt=""
        class="wishlist-lens-icon"
      />
      <span class="details-category">{{ select.categoryName }}</span>
    </div>
    <div class="lens-pdf-heading">Lens Specifications</div>
    <div>
      <div class="details-container">
        <div class="details-top-row">
          <div class="details-row">
            <div class="details-col">
              <div class="detail-head">Focal Length:</div>
              <div class="detail-value">
                {{
                  select.wishlist.focalLength
                    ? select.wishlist.focalLength
                    : "N/A"
                }}
              </div>
            </div>
            <div class="details-col">
              <div class="detail-head">Transmission:</div>
              <div class="detail-value">
                {{
                  select.wishlist.transmission
                    ? select.wishlist.transmission
                    : "N/A"
                }}
              </div>
            </div>
          </div>
          <div class="details-row">
            <div class="details-col">
              <div class="detail-head">Manufacturer:</div>
              <div class="detail-value">
                {{
                  select.wishlist.manufacturer
                    ? select.wishlist.manufacturer
                    : "N/A"
                }}
              </div>
            </div>
            <div class="details-col">
              <div class="detail-head cliphead">Series:</div>
              <div class="value-box-w-tooltip">
                <div class="clipped-tooltip" v-if="select.wishlist.series">
                  {{ select.wishlist.series }}
                </div>
                <div class="detail-value clip">
                  {{ select.wishlist.series ? select.wishlist.series : "N/A" }}
                </div>
              </div>
            </div>
          </div>
          <div class="details-row">
            <div class="details-col">
              <div class="detail-head">Lens Type:</div>
              <div class="detail-value">
                {{
                  select.wishlist.lensType ? select.wishlist.lensType : "N/A"
                }}
              </div>
            </div>
            <div class="details-col">
              <div class="detail-head">Lens Format:</div>
              <div class="detail-value">
                {{
                  select.wishlist.lensFormat
                    ? select.wishlist.lensFormat
                    : "N/A"
                }}
              </div>
            </div>
          </div>
          <div class="details-row">
            <div class="details-col">
              <div class="detail-head">Image Circle:</div>
              <div class="detail-value">
                {{
                  select.wishlist.imageCircle
                    ? select.wishlist.imageCircle
                    : "N/A"
                }}
              </div>
            </div>
            <div class="details-col">
              <div class="detail-head">Aperture Range:</div>
              <div class="detail-value">
                {{
                  select.wishlist.apertureRange
                    ? select.wishlist.apertureRange
                    : "N/A"
                }}
              </div>
            </div>
          </div>
          <div class="details-row">
            <div class="details-col">
              <div class="detail-head">Distortion:</div>
              <div class="detail-value">
                {{
                  select.wishlist.distortion
                    ? select.wishlist.distortion
                    : "N/A"
                }}
              </div>
            </div>
            <div class="details-col">
              <div class="detail-head">Weight:</div>
              <div class="detail-value">
                {{ select.wishlist.weight ? select.wishlist.weight : "N/A" }}
              </div>
            </div>
          </div>
          <div class="details-row">
            <div class="details-col">
              <div class="detail-head">Squeeze Factor:</div>
              <div class="detail-value">
                {{
                  select.wishlist.squeezeFactor
                    ? select.wishlist.squeezeFactor
                    : "N/A"
                }}
              </div>
            </div>

            <div class="details-col">
              <div class="detail-head">Year:</div>
              <div class="detail-value">
                {{ select.wishlist.year ? select.wishlist.year : "N/A" }}
              </div>
            </div>
          </div>

          <div class="details-row">
            <div class="details-col">
              <div class="detail-head">Country of Origin:</div>
              <div class="detail-value">
                {{
                  select.wishlist.countryOfOrigin
                    ? select.wishlist.countryOfOrigin
                    : "N/A"
                }}
              </div>
            </div>

            <!-- Empty col to hold the layout if only one colum is required-->
            <div class="details-col">
              <div class="detail-head">Close Focus:</div>
              <div class="detail-value">
                {{
                  select.wishlist.closeFocus
                    ? select.wishlist.closeFocus
                    : "N/A"
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="details-bottom-row">
          <div class="details-bottom-left">
            <div class="details-row">
              <div class="details-col">
                <div class="detail-head">Flare Type:</div>
                <div class="detail-value tag">
                  {{
                    getLensPropOptionLabel(
                      "flareType",
                      select?.wishlist?.flareType
                    ) || "N/A"
                  }}
                </div>
              </div>
            </div>
            <div class="details-row">
              <div class="details-col">
                <div class="detail-head">Flare Color:</div>
                <div class="detail-value tag">
                  {{
                    getLensPropOptionLabel(
                      "flareColor",
                      select?.wishlist?.flareColor
                    ) || "N/A"
                  }}
                </div>
              </div>
            </div>
            <div class="details-row">
              <div class="details-col">
                <div class="detail-head">Flare Intensity:</div>
                <div class="detail-value tag">
                  {{
                    getLensPropOptionLabel(
                      "flareIntensity",
                      select?.wishlist?.flareIntensity
                    ) || "N/A"
                  }}
                </div>
              </div>
            </div>
            <div class="details-row">
              <div class="details-col">
                <div class="detail-head">Lens Era:</div>
                <div class="detail-value tag">
                  {{
                    getLensPropOptionLabel(
                      "lensEra",
                      select?.wishlist?.lensEra
                    ) || "N/A"
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="details-bottom-right">
            <div
              class="films-row"
              v-if="select.wishlist.notableFilms?.length > 0"
            >
              <div class="films-head">Notable Films:</div>
              <div class="films-data-box">
                <div
                  class="films-data detail-value"
                  v-for="film in select.wishlist.notableFilms"
                  :key="film.link"
                >
                  <a :href="film.link" target="_blank"
                    >{{ film.name }}
                    <img src="@/assets/img/link-icon.svg" alt=""
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="select-pdf-table">
      <table cellpadding="0">
        <thead>
          <th>Focal Length</th>
          <th>Max Apreture</th>
          <th>Close Focus</th>
          <th>Weight</th>
          <th>Length</th>
          <th>Image Circle</th>
          <th>Front Diameter</th>
        </thead>
        <tbody>
          <td>
            {{
              select.wishlist.focalLength ? select.wishlist.focalLength : "N/A"
            }}
          </td>
          <td>
            {{
              select.wishlist.apertureRange
                ? select.wishlist.apertureRange
                : "N/A"
            }}
          </td>
          <td>
            {{
              select.wishlist.closeFocus ? select.wishlist.closeFocus : "N/A"
            }}
          </td>
          <td>
            {{ select.wishlist.weight ? select.wishlist.weight : "N/A" }}
          </td>
          <td>
            {{ select.wishlist.length ? select.wishlist.length : "N/A" }}
          </td>
          <td>
            {{
              select.wishlist.imageCircle ? select.wishlist.imageCircle : "N/A"
            }}
          </td>
          <td>
            {{
              select.wishlist.frontDiameter
                ? select.wishlist.frontDiameter
                : "N/A"
            }}
          </td>
        </tbody>
      </table>
    </div>
    <p class="pdf-copyrignt">
      © 2024 CINEFLARES. ALL RIGHTS RESERVED. TEST CONCEPTUALIZED AND PERFORMED
      BY MARKUS FORDERER, ASC
    </p>
  </section>
</template>

<script setup>
import client from "@/api/client";
import { getLensPropOptionLabel } from "@/utils/string";
import { defineProps, onMounted, ref } from "vue";

const props = defineProps(["select"]);
const baseImgUrl = ref(null);
const iconBase64 = ref(null);

const convertToBase64 = async (url) => {
  try {
    const response = await client.get(
      `${process.env.VUE_APP_BASE_URL}/image/base64?url=${url}`
    );

    return response.data.base64;
  } catch (err) {
    console.log(err);
  }
};

onMounted(async () => {
  baseImgUrl.value = await convertToBase64(
    props.select.sceneImageUrl || props.select.flareImage
  );
  iconBase64.value = await convertToBase64(props.select.wishlist.icon);
});
</script>

<style scoped>
html {
  background: red;
}

.films-row {
  opacity: 1;
  color: black;
}

.details-col {
  opacity: 1;
}

.detail-value.tag {
  color: black;
}

.detail-value {
  color: black;
}

.details-category {
  margin-bottom: 0;
  border-color: gray;
}
</style>
