<template>
  <div class="main">
    <AlertBox v-if="showComingSoonAlert" @closeAlert="showComingSoonAlert = false">
      <div class="premium-upgrade-box">
        <img src="@/assets/img/premium-icon.svg" alt="" />
        <h4 class="gradient-text">Coming Soon</h4>
        <p>We will notify you once the pro version is available.</p>
      </div>
    </AlertBox>
    <div class="mobile_nav">
      <router-link to="/"><img :src="userStore.logoSource" class="logo" /></router-link>

      <img src="../../assets/img/hamburger.svg" class="logo" @click="showNavBar" />
    </div>
    <div class="menu-top-nav-bar">
      <ul>
        <li>SELECTS</li>
        <li>ABOUT</li>
        <li>NEWS</li>
      </ul>
    </div>
    <Transition name="sidebar">
      <div id="mobileSidebar" @click="hideNavBar" v-if="showSidebar">
        <sidebar-menu :menu="menuItems" width="60%" :rtl="true" :hideToggle="true" @item-click="onItemClick" />
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import "../../styles/MobileComponents/Header.css";
import { useUsersStore } from "@/stores/Users";
import { useRouter } from "vue-router";

import { storeToRefs } from "pinia";
import AlertBox from "../AlertBox.vue";
import config from "@/config/config";

const MENU_ARRAY = [
  {
    href: "/",
    title: "FLARES",
    class: "mobile-menu-item",
    requiresAuth: true,
  },
  {
    href: "/browse",
    title: "BROWSE",
    class: "mobile-menu-item",
    requiresAuth: true,
    requiresPremium: true,
  },
  {
    href: "/scene",
    title: "SCENE",
    class: "mobile-menu-item admin-only",
    requiresAuth: true,
  },
  {
    href: "/selects",
    title: "SELECTS",
    class: "mobile-menu-item",
    requiresAuth: true,
  },
  {
    href: "/about",
    title: "ABOUT",
    class: "mobile-menu-item",
    requiresAuth: false,
  },
  {
    href: "/news",
    title: "NEWS",
    class: "mobile-menu-item",
    requiresAuth: false,
  },
  {
    href: "/pricing",
    title: "PRICING",
    class: "mobile-menu-item",
    requiresAuth: false,
    hideIfPremium: true,
  },
  {
    href: "/account",
    title: "ACCOUNT",
    class: "mobile-menu-item",
    requiresAuth: true,
  },
  { title: "LOGOUT", class: "mobile-menu-item", requiresAuth: true },
  {
    href: "/signup",
    title: "SIGNUP",
    class: "mobile-menu-item",
    hideWhenLogged: true,
  },
  {
    href: "/login",
    title: "LOGIN",
    class: "mobile-menu-item",
    hideWhenLogged: true,
  },
];

const showSidebar = ref(false);
const showComingSoonAlert = ref(false);
const userStore = useUsersStore();
const { isLoggedInValue, userDetails, userRole } = storeToRefs(userStore);
const router = useRouter();

const menuItems = ref([]);
async function onItemClick(event, item) {
  if (item.title === "LOGOUT") {
    await userStore.logOut();
    router.push(`/login`);
  }
  if (item.title === "PRICING") {
    if (config.enablePayment) router.push(`/pricing`);
    else showComingSoonAlert.value = true;
  }
}

const generateMenu = () => {
  let menuArray = MENU_ARRAY;

  if (!userStore.isLoggedInValue) {
    // logged out menu
    menuArray = menuArray.filter((item) => !item.requiresAuth);
  } else {
    //Logged in menu
    menuArray = menuArray.filter((item) => !item.hideWhenLogged);

    // Logged in and is not premium user
    if (!userDetails?.value?.isPremiumUser) {
      menuArray = menuArray.filter((item) => !item.requiresPremium);
    } else {
      menuArray = menuArray.filter((item) => !item.hideIfPremium);
    }

    if (userStore.userRole !== "ADMIN") {
      menuArray = menuArray.filter((item) => !item.requiresAdmin);
    }
  }

  menuItems.value = menuArray;
};

watch(isLoggedInValue, () => {
  generateMenu();
});

watch(userDetails, () => {
  generateMenu();
});

watch(userRole, () => {
  generateMenu();
});

onMounted(() => {
  generateMenu();
});

const showNavBar = () => {
  showSidebar.value = true;
};

const hideNavBar = (e) => {
  if (e.target.classList.contains("vsm--scroll")) return;

  showSidebar.value = false;
};
</script>

<style scoped>
#mobileSidebar {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  top: 0;
}
</style>
