<template>
  <AlertBox v-if="showPremiumAlert" @closeAlert="showPremiumAlert = false">
    <div class="premium-upgrade-box">
      <img src="../../assets/img/premium-icon.svg" alt="" />
      <h4 class="gradient-text">Pro feature only</h4>
      <p>
        Upgrade to PRO account to unlock more features and access to our full
        PRO library.
      </p>
      <RouterLink class="pricing-link" to="/pricing">View Plans</RouterLink>
    </div>
  </AlertBox>
  <div class="scenepage secondary-body">
    <div class="container newsbody-container logo-container">
      <div class="column left"></div>
      <div class="column middle">
        <div class="site-logo">
          <img src="@/assets/img/cineflares-pro-logo-black.webp" alt="cineflares" />
        </div>
        <h2 class="title">Scene preview</h2>
      </div>
      <div class="column right"></div>
    </div>

    <section id="selects-section">
      <div class="container">
        <div class="column left"></div>
        <div class="column middle">
          <div class="slider-container" @contextmenu.prevent="disableRightClick" ref="sliderContainer">
            <div :class="{ 'fullscreen-div': isFullScreen }">
              <div class="spinner-container" v-if="sliderLoading">
                <div class="spinner-load"></div>
              </div>

              <!-- Left save Button -->
              <SaveBtn :status="leftSaveBtnStatus" @click="addWishlist('LEFT')" :loading="leftSaveBtnLoading"
                :viewCondition="!isFullScreen" :sceneSave="{ isScene: true, isLeft: true }" />

              <!-- Right save Button -->
              <SaveBtn :status="rightSaveBtnStatus" @click="addWishlist('RIGHT')" :loading="rightSaveBtnLoading"
                :viewCondition="!isFullScreen" :sceneSave="{ isScene: true, isLeft: false }" />
              <ImgComparisonSlider style="display: block" class="image-comparison-slider">
                <!-- eslint-disable -->

                <img slot="first" style="width: 100%; aspect-ratio: 2.4 / 1" ref="sceneLeftRef"
                  src="@/assets/img/scene_load.png" class="fade-in-image" />

                <svg width="30" slot="handle" viewBox="0 0 425 614" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="425" height="614" rx="212" fill="white" />
                  <path
                    d="M146 202.5V412.5C146 422.16 138.16 430 128.5 430C118.84 430 111 422.16 111 412.5V202.5C111 192.84 118.84 185 128.5 185C138.16 185 146 192.84 146 202.5ZM195 202.5V412.5C195 422.16 202.84 430 212.5 430C222.16 430 230 422.16 230 412.5V202.5C230 192.84 222.16 185 212.5 185C202.84 185 195 192.84 195 202.5ZM279 202.5V412.5C279 422.16 286.84 430 296.5 430C306.16 430 314 422.16 314 412.5V202.5C314 192.84 306.16 185 296.5 185C286.84 185 279 192.84 279 202.5Z"
                    fill="black" />
                </svg>

                <img slot="second" style="width: 100%; aspect-ratio: 2.4 / 1" ref="sceneRightRef"
                  src="@/assets/img/scene_load.png" class="fade-in-image" />

                <!-- eslint-enable -->
              </ImgComparisonSlider>
            </div>
            <div class="scene-fullscreen" @click="requestSceneFullscreen">
              <img src="@/assets/img/fullscreen.svg" width="12" v-if="!isFullScreen" />
              <img src="@/assets/img/exit-fullscreen.svg" width="15" v-else />
            </div>
          </div>

          <div class="selector">
            <div class="sub-column">
              <div class="scene-div-left">
                <div style="display: flex">
                  <ExpandableSearchComponent @focus-event="searchFocus" @blur-event="searchBlur" :isLeftSearch="true"
                    :isScene="true" style="margin-left: 0px!; margin-right: 0px!" />
                  <img id="iconLeftScene" ref="iconLeftScene" style="margin-left: 120px" />
                  <select ref="lensNameSceneOptionLeft" id="lensNameSceneOption" name="lensNameSceneLeftOption"
                    @change="setLensName($event, 'LEFT')">
                    <optgroup v-for="group in store.lensesName" :key="group.category" :label="group.category"
                      id="lensCategoryOption" color="red">
                      <option v-for="lens in group.lenses" :key="lens.name" :value="lens.name"
                        :selected="store.LeftSelectedVideoDetails[0]?.name === lens.name">
                        {{ lens.name }}
                      </option>
                    </optgroup>
                  </select>
                  <select ref="focalLengthLeftScene" id="focalLengthLeftScene" name="focalLengthLeftScene"
                    @change="setFocalLengthChange($event, 'LEFT')">
                    <option v-for="option in store.focalLengthOptionsLeft" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                  <select ref="transmissionLeftScene" id="transmissionLeftScene" name="transmissionLeftScene"
                    @change="setTransmission($event, 'LEFT')">
                    <option v-for="option in store.transmissionsLeft" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="sub-column">
              <div class="scene-div-right">
                <div style="display: flex">
                  <ExpandableSearchComponent @focus-event="searchFocus" @blur-event="searchBlur" :isLeftSearch="false"
                    :isScene="true" style="margin-left: 0px!; margin-right: 0px!" />
                  <img id="iconRightScene" ref="iconRightScene" style="margin-left: 120px" />
                  <select ref="lensNameSceneOptionRight" id="lensNameSceneOption" name="lensNameSceneRightOption"
                    @change="setLensName($event, 'RIGHT')">
                    <optgroup v-for="group in store.lensesName" :key="group.category" :label="group.category"
                      id="lensCategoryOption">
                      <option v-for="lens in group.lenses" :key="lens.name" :value="lens.name"
                        :selected="store.RightSelectedVideoDetails[0]?.name === lens.name">
                        {{ lens.name }}
                      </option>
                    </optgroup>
                  </select>

                  <select ref="focalLengthRightScene" id="focalLengthRightScene" name="focalLengthRightScene"
                    @change="setFocalLengthChange($event, 'RIGHT')">
                    <option v-for="option in store.focalLengthOptionsRight" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                  <select ref="transmissionRightScene" id="transmissionRightScene" name="transmissionRightScene"
                    @change="setTransmission($event, 'RIGHT')">
                    <option v-for="option in store.transmissionsRight" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column right"></div>
      </div>
    </section>
    <div class="container">
      <div class="column left"></div>

      <div class="column middle">
        <div class="scene-control-wrapper" style="padding-bottom: 24px">
          <!-- <div class="scene-control-left">
              <div style="padding-bottom: 12px">
                <span>Aspect Ratio</span>
                <span class="scene-prop">2.39:1</span>
                <span class="scene-prop prop-active">2:1</span>
                <span class="scene-prop">1.78:1</span>
              </div>
              <div>
                <span>Shooting Format</span>
                <span class="scene-prop">S35</span>
                <span class="scene-prop prop-active">FF</span>
              </div>
            </div> -->
          <div class="scene-control-right">
            <span class="scene-tab" :class="{ 'tab-active': store.sceneType === 'desert' }"
              @click="setSceneType('desert')">DESERT</span>
            <span class="scene-tab" :class="{ 'tab-active': store.sceneType === 'night' }"
              @click="handlePremiumAlert()">NIGHT</span>
            <CustomTooltip text="Coming Soon">
              <span class="scene-tab" :class="{ 'tab-active': store.sceneType === 'interior' }">INTERIOR</span>
            </CustomTooltip>
          </div>
        </div>
        <div class="scene-control-instruction" style="margin-top: 24px">
          Simulated scene preview. Select your scene, lens, focal length and T-stop. You
          can swipe to compare lenses. This feature is showing a representation of how
          lenses would react to a bright light source in different environments. By
          overlaying optically captured lens flares onto your selected scene in a
          photometrically accurate way, and matching FOV and distortion of the lens
          selected, you can compare all lenses from our database in a controlled way.
        </div>
      </div>
      <div class="column right"></div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useUsersStore } from "@/stores/Users";
import { ImgComparisonSlider } from "@img-comparison-slider/vue";
import CustomTooltip from "@/components/CustomTooltip.vue";
import "@/styles/Body.css";
import { storeToRefs } from "pinia";
import { useScenesStore } from "@/stores/Scenes";
// import SearchComponent from "@/components/SearchComponent.vue";
import { useOverlayStore } from "@/stores/Overlay";
import SaveBtn from "../Selects/SaveBtn.vue";
import AlertBox from "../AlertBox.vue";
import { addToWishlist, removeFromWishlist } from "@/api/wishlist";
import { findlensesExistanceInSceneType, requestFullScreen } from "@/includes/scene";
import { useWishlistStore } from "@/stores/Wishlist";
import ExpandableSearchComponent from "@/components/ExpandableSearchComponent.vue";
import { ampli } from "@/ampli";
import { useRouter } from "vue-router";

import { disableRightClick } from "@/includes/helper";

const focalLengthLeftScene = ref(null);
const focalLengthRightScene = ref(null);
const transmissionLeftScene = ref(null);
const transmissionRightScene = ref(null);
const leftSingleVideoDetail = ref(null);
const rightSingleVideoDetail = ref(null);
const iconLeftScene = ref(null);
const iconRightScene = ref(null);
const searchFocused = ref(false);
const sceneRightRef = ref(null);
const sceneLeftRef = ref(null);
const sliderLoading = ref(false);
const leftSaveBtnStatus = ref(null);
const rightSaveBtnStatus = ref(null);
const leftSaveBtnLoading = ref(false);
const rightSaveBtnLoading = ref(false);

const sliderContainer = ref(null);
const isFullScreen = ref(false);
const showPremiumAlert = ref(false);

const store = useScenesStore();
const userStore = useUsersStore();
const overlayStore = useOverlayStore();
const wishlistStore = useWishlistStore();
const router = useRouter();

let currentPathObject = router.currentRoute.value;

const sceneWishlist = computed(() =>
  wishlistStore.wishlist.filter((item) => item.sceneImageUrl)
);

const handlePremiumAlert = () => {
  if (userStore?.userDetails?.isPremiumUser) {
    showPremiumAlert.value = false;
    setSceneType('night')
  } else {
    showPremiumAlert.value = true;
  }
};

const {
  LeftSelectedVideoDetails,
  RightSelectedVideoDetails,
  LeftSelectedVideoId,
  RightSelectedVideoId,
  LeftFocalLength,
  RightFocalLength,
  sceneType,
  LeftTransmission,
  RightTransmission,
} = storeToRefs(store);

const searchFocus = () => {
  searchFocused.value = true;
};

const searchBlur = () => {
  searchFocused.value = false;
};

watch(sceneType, async () => {
  // store.setVideoPathOnChange(lensesItem, video1Ref);
  store.loadDefaultVideoPath(sceneRightRef, "RIGHT");
  store.loadDefaultVideoPath(sceneLeftRef, "LEFT");

  generateLeftSaveIcon();
  generateRightSaveIcon();

  await store.fetchData();

  const leftLensesExistInSceneType = findlensesExistanceInSceneType(
    store.LeftSelectedVideoId,
    store.data,
    false
  );
  const rightLensesExistInSceneType = findlensesExistanceInSceneType(
    store.RightSelectedVideoId,
    store.data,
    false
  );

  if (!leftLensesExistInSceneType) {
    setLensName(null, "LEFT");
  }

  if (!rightLensesExistInSceneType) {
    setLensName(null, "RIGHT");
  }
});

watch(LeftTransmission, () => {
  const lensesItem = store.LeftSelectedVideoDetails.find(
    (s) =>
      s?.focalLength === store.LeftFocalLength &&
      s?.transmission === store.LeftTransmission
  );
  store.generateIcon(lensesItem?.icon, iconLeftScene);
  store.setVideoPathOnChange(lensesItem, sceneLeftRef);

  leftSingleVideoDetail.value = lensesItem;
});

watch(RightTransmission, () => {
  const lensesItem = store.RightSelectedVideoDetails.find(
    (s) =>
      s?.focalLength === store.RightFocalLength &&
      s?.transmission === store.RightTransmission
  );

  rightSingleVideoDetail.value = lensesItem;

  store.generateIcon(lensesItem?.icon, iconRightScene);
  store.setVideoPathOnChange(lensesItem, sceneRightRef);
});

// watcher to monitor change in left focal length
watch(LeftFocalLength, () => {
  store.setLeftSelectedVideoDetails();

  reloadTransmission("LEFT");
  const lensesItem = store.LeftSelectedVideoDetails.find(
    (s) =>
      s?.focalLength === store.LeftFocalLength &&
      s?.transmission === store.LeftTransmission
  );

  leftSingleVideoDetail.value = lensesItem;
  store.generateIcon(lensesItem?.icon, iconLeftScene);
  store.setVideoPathOnChange(lensesItem, sceneLeftRef);
});

watch(RightFocalLength, () => {
  store.setRightSelectedVideoDetails();
  reloadTransmission("RIGHT");
  const lensesItem = store.RightSelectedVideoDetails.find(
    (s) =>
      s?.focalLength === store.RightFocalLength &&
      s?.transmission === store.RightTransmission
  );

  rightSingleVideoDetail.value = lensesItem;

  store.generateIcon(lensesItem?.icon, iconRightScene);
  store.setVideoPathOnChange(lensesItem, sceneRightRef);
});

function reloadTransmission(target) {
  const lensesDetails =
    target === "LEFT" ? store.LeftSelectedVideoDetails : store.RightSelectedVideoDetails;

  lensesDetails.sort((a, b) => {
    const focalLengthA = a?.focalLength
      ? parseFloat(a?.focalLength.replace("mm", ""))
      : 0;
    const focalLengthB = b?.focalLength
      ? parseFloat(b?.focalLength.replace("mm", ""))
      : 0;
    const transmissionA = a.transmission
      ? parseFloat(a.transmission.replace("T", ""))
      : 0;
    const transmissionB = b?.transmission
      ? parseFloat(b?.transmission.replace("T", ""))
      : 0;
    const focalDiff = focalLengthA - focalLengthB;
    if (focalDiff !== 0) {
      return focalDiff;
    }
    return transmissionA - transmissionB;
  });
  const transmissonOptions =
    target === "LEFT"
      ? lensesDetails.filter((s) => s?.focalLength === store.LeftFocalLength)
      : lensesDetails.filter((s) => s?.focalLength === store.RightFocalLength);

  // delete previous transmissions and set a new one
  const transmissionId =
    target === "LEFT" ? "transmissionLeftScene" : "transmissionRightScene";
  const transmissionElement = document.getElementById(transmissionId);

  while (transmissionElement?.options?.length > 0) {
    transmissionElement.remove(0);
  }

  target === "LEFT"
    ? store.setLeftTransmission(transmissonOptions[0]?.transmission)
    : store.setRightTransmission(transmissonOptions[0]?.transmission);

  transmissonOptions?.forEach((option) => {
    const optionElement = document.createElement("option");
    optionElement.value = option.transmission;
    optionElement.text = option.transmission;

    // optionElement.selected =
    //   option.transmission === selectedTransmission ? "selected" : "";
    transmissionElement.appendChild(optionElement);
  });
  transmissionElement.style.display = "block";
}
const generateLeftSaveIcon = () => {
  if (!leftSingleVideoDetail.value) return;
  const { id } = leftSingleVideoDetail.value;

  let idArray = [];

  sceneWishlist.value?.forEach((obj) => {
    idArray.push({ lensId: obj.lensId, sceneType: obj.sceneImageType });
  });

  let currentScene = getSceneType();

  for (const object of idArray) {
    if (object.lensId === id && object.sceneType === currentScene.sceneType) {
      leftSaveBtnStatus.value = "saved";
      return;
    }
  }

  leftSaveBtnStatus.value = "unsaved";
};

const generateRightSaveIcon = () => {
  if (!rightSingleVideoDetail.value) return;
  const { id } = rightSingleVideoDetail.value;

  let idArray = [];

  sceneWishlist.value?.forEach((obj) => {
    idArray.push({ lensId: obj.lensId, sceneType: obj.sceneImageType });
  });

  let currentScene = getSceneType();

  for (const object of idArray) {
    if (object.lensId === id && object.sceneType === currentScene.sceneType) {
      rightSaveBtnStatus.value = "saved";
      return;
    }
  }

  rightSaveBtnStatus.value = "unsaved";
};

const getCategoryName = (lensId) => {
  for (const category of store.data) {
    for (const lens of category.lenses) {
      if (lens.id === lensId) {
        return category.name;
      }
    }
  }

  return "";
};

const getSceneType = (scene) => {
  let sceneType, sceneImageUrl;

  switch (store.sceneType) {
    case "desert":
      sceneType = "sceneImage1";
      if (scene) sceneImageUrl = scene.sceneImage1;
      break;
    case "night":
      sceneType = "sceneImage2";
      if (scene) sceneImageUrl = scene.sceneImage2;
      break;
    case "interior":
      sceneType = "sceneImage3";
      if (scene) sceneImageUrl = scene.sceneImage3;
      break;
  }

  return { sceneType, sceneImageUrl };
};

const handleUnsave = async (lensId, target) => {
  let currentScene = getSceneType();

  const wishlistData = sceneWishlist.value.find(
    (wishlist) =>
      wishlist.lensId === lensId && wishlist.sceneImageType === currentScene.sceneType
  );

  if (!wishlistData) return;
  await removeFromWishlist(wishlistData.id);

  wishlistStore.removeFromWishlist(wishlistData.id);

  target === "LEFT"
    ? (leftSaveBtnLoading.value = false)
    : (rightSaveBtnLoading.value = false);
};

const addWishlist = async (target) => {
  let videoObject;
  let status;
  let lensCategoryName;

  if (target === "LEFT") {
    const items = LeftSelectedVideoDetails.value;
    videoObject = items.filter((item) => {
      return (
        item?.focalLength === store.LeftFocalLength &&
        item?.transmission === store.LeftTransmission
      );
    });
    status = leftSaveBtnStatus.value;

    lensCategoryName = getCategoryName(videoObject[0].id);

    leftSaveBtnLoading.value = true;
  } else {
    const items = RightSelectedVideoDetails.value;
    videoObject = items.filter((item) => {
      return (
        item?.focalLength === store.RightFocalLength &&
        item?.transmission === store.RightTransmission
      );
    });
    status = rightSaveBtnStatus.value;

    lensCategoryName = getCategoryName(videoObject[0].id);

    rightSaveBtnLoading.value = true;
  }

  const selectedScene = videoObject[0];

  const { id: lensId, name, focalLength, transmission, videoPath } = selectedScene;

  if (status === "saved") {
    handleUnsave(lensId, target);
    return;
  }

  const payload = {
    lensId,
    name,
    focalLength,
    transmission,
    sceneImageType: getSceneType(selectedScene).sceneType,
    sceneImageUrl: getSceneType(selectedScene).sceneImageUrl,
    videoPath,
    categoryName: lensCategoryName,
  };

  /*
    AMPLITUDE: Track lens saved
    Triggers when a lens save button is clicked
  */
  ampli.lensSaved({
    lensId: lensId,
    lensName: name,
    focalLength: focalLength,
    transmission: transmission,
    page: currentPathObject?.fullPath,
  });

  const response = await addToWishlist(payload);

  if (response.status === 200) {
    wishlistStore.addToWishlist(response.data);
  }

  leftSaveBtnLoading.value = false;
  rightSaveBtnLoading.value = false;
};

const requestSceneFullscreen = () => {
  if (isFullScreen.value) {
    document.exitFullscreen();
  } else {
    requestFullScreen(sliderContainer.value);
  }
};

const handleFullscreen = () => {
  if (document.fullscreenElement) {
    isFullScreen.value = true;
  } else {
    isFullScreen.value = false;
  }
};

onMounted(async () => {
  document.querySelector("body").classList.add("backgroundLight");

  overlayStore.showLoadingOverlay();

  await store.fetchData();

  overlayStore.hideLoadingOverlay();

  const defaultLeft = store.defaultLeftVideoId || process.env.VUE_APP_DEFAULT_LEFT;
  const defaultRight = store.defaultRightVideoId || process.env.VUE_APP_DEFAULT_RIGHT;

  store.setLeftSelectedVideoId(defaultLeft);
  store.setRightSelectedVideoId(defaultRight);
  store.setRightSelectedVideoDetails();
  store.setLeftSelectedVideoDetails();
  document.addEventListener("fullscreenchange", handleFullscreen);
});

onUnmounted(() => {
  store.LeftSelectedVideoId = null;
  store.RightSelectedVideoId = null;
  store.LeftFocalLength = null;
  store.RightFocalLength = null;
  document.querySelector("body").classList.remove("backgroundLight");
  document.removeEventListener("fullscreenchange", handleFullscreen);
});

watch(sceneWishlist, () => {
  generateLeftSaveIcon();
  generateRightSaveIcon();
});

watch(rightSingleVideoDetail, () => {
  generateLeftSaveIcon();
  generateRightSaveIcon();
});
watch(leftSingleVideoDetail, () => {
  generateLeftSaveIcon();
  generateRightSaveIcon();
});

//watcher to monitor change in leftvideo Id
watch(LeftSelectedVideoId, () => {
  if (store.searchIsActive) {
    const navEntities = document.querySelectorAll(".nav-entity-left");
    navEntities.forEach((item) => {
      item.style.transform = "rotateX(0deg)";
    });
  }
  store.setLeftSelectedVideoDetails();
  store.setLeftFocalLength(store.LeftSelectedVideoDetails[0]?.focalLength);
  store.setLeftTransmission(store.LeftSelectedVideoDetails[0]?.transmission);
  store.generateDropDown("LEFT", focalLengthLeftScene, transmissionLeftScene);
  store.loadDefaultVideoPath(sceneLeftRef, "LEFT");
});

//watcher to monitor change in leftvideo Id
watch(RightSelectedVideoId, () => {
  if (store.searchIsActive) {
    const navEntities = document.querySelectorAll(".nav-entity-right");
    navEntities.forEach((item) => {
      item.style.transform = "rotateX(0deg)";
    });
  }
  store.setRightSelectedVideoDetails();
  store.setRightFocalLength(store.RightSelectedVideoDetails[0]?.focalLength);
  store.setRightTransmission(store.RightSelectedVideoDetails[0]?.transmission);
  store.generateDropDown("RIGHT", focalLengthRightScene, transmissionRightScene);
  store.loadDefaultVideoPath(sceneRightRef, "RIGHT");
});

function setFocalLengthChange(event, target) {
  const selectedFocalLength = event.target.value;
  target === "LEFT"
    ? store.setLeftFocalLength(selectedFocalLength)
    : store.setRightFocalLength(selectedFocalLength);
}

function setLensName(event, target) {
  const selectedLensName = event?.target?.value;

  if (target === "LEFT") {
    // If lens does not exist for scenetype, default is selected
    if (selectedLensName) {
      store.setLeftSelectedVideoId(
        selectedLensName.toLowerCase().replace(/\s+/g, "-") + "-left"
      );
    } else {
      store.setLeftSelectedVideoId(store.defaultLeftVideoId);
    }
  } else {
    if (selectedLensName) {
      store.setRightSelectedVideoId(
        selectedLensName.toLowerCase().replace(/\s+/g, "-") + "-right"
      );
    } else {
      store.setRightSelectedVideoId(store.defaultRightVideoId);
    }
  }
}

function setTransmission(event, target) {
  const selectedTransmission = event.target.value;
  target === "LEFT"
    ? store.setLeftTransmission(selectedTransmission)
    : store.setRightTransmission(selectedTransmission);
}

function setSceneType(scene) {
  store.setSceneType(scene);
}
</script>

<style scoped>
.middle {
  flex: 5;
  display: block;
}

.scenepage {
  color: black !important;
}

.select-row {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  flex-wrap: wrap;
}

.detailsContainer {
  margin-top: 80px;
  display: flex;
  color: white;
}

.mainContainer {
  overflow: scroll;
}

.title {
  color: rgb(32, 32, 32);
  text-transform: uppercase;
  font-family: "figtree-bold";
  letter-spacing: 1px;
  opacity: 0.95;
  font-size: 32px;
  font-weight: 400;
}

.details-middle {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.column.middle {
  position: relative;
}

.selector {
  display: flex;
  margin-top: 24px;
}

#video-label-right {
  font-size: 12px;
  font-weight: 800;
}

#video-label-left {
  font-size: 12px;
  font-weight: 800;
}

#lensCategoryOption {
  color: #007bff !important;
  /* Change this to your desired text color */
}

.scene-control-instruction {
  padding-left: 20%;
  padding-right: 20%;
  text-align: center;
  color: rgb(190, 190, 190);
  line-height: 1.5;
  font-size: 14px;
  font-family: "figtree-regular";
}

.scene-prop {
  padding-left: 8px;
}

.prop-active {
  font-weight: 900;
}

/* styles.css */
.fade-in-image {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in-image.show {
  opacity: 1;
}

.search-input {
  color: rgb(70, 70, 70);
}

.image-comparison-slider {
  --divider-width: 4px;
  cursor: pointer;
}

.scene-div-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* gap: 10px; */
}

.scene-div-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* gap: 10px; */
}

.fullscreen-div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .middle {
    flex: 10;
  }
}

@media screen and (max-width: 1024px) {
  .logo-container {
    display: none;
  }

  .secondary-body {
    display: flex;
    padding-top: 0;
  }
}

#lensNameSceneOption {
  width: 200px;
}
</style>
